import request from '@/utils/request'

// 公告列表
export const queryNoticeList = (params) => {
  return request('GET', '/app/notice/list', params, true)
}

// 公告详情
export const queryNoticeDetail = (params) => {
  return request('GET', '/app/notice/details', params)
}
