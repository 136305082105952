<template>
  <div class="announcement-details-wrap">
    <div v-if="loading">
      <van-skeleton title :row="3" />
    </div>
    <div class="announcement-details-box" v-else>
      <div class="title">{{ notice.englishTitle }}</div>
      <!-- <div class="time">{{ notice.createTime }}</div> -->
      <div class="padding-layout">
        <div class="announcement-content">
          <div v-html="notice.englishText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { queryNoticeDetail, queryNoticeList } from '@/services/notice'
export default {
  name: 'announcementDetail',
  data () {
    return {
      id: '',
      notice: {},
      loading: true
    }
  },
  computed: {
    ...mapState(['mbLang'])
  },
  methods: {
    async getDetail () {
      const params = {
        id: this.id
      }
      const resp = await queryNoticeDetail(params)
      console.log(resp)
      if (!resp.success) return
      this.notice = resp.result || {}
      // this.notice.createTime = this.notice.createTime.replaceAll('-', ':')
      console.log(this.notice.createTime)
    },
    async getAnnouncementList () {
      this.loading = true
      const resp = await queryNoticeList({
        pageNumber: 1,
        pageSize: 1
      })
      this.loading = false
      if (!resp.success) return
      this.notice = resp.result.records[0] || {}
    }
  },
  mounted () {
    this.id = this.$route.query.id || ''
    // this.getDetail()
    this.getAnnouncementList()
  }
}
</script>

<style lang="scss">
.announcement-details-wrap {
  width: 100%;
  min-height: calc(100vh - 88px);
}

.announcement-details-wrap {
  .announcement-details-box {
    position: relative;
    box-sizing: border-box;
    background-size: 100% auto;
    width: 100%;
    min-height: 500px;
    .announcement-title {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 64px;
      width: 140px;
      line-height: 60px;
      font-size: 18px;
      color: #16172a;
      text-align: center;
      margin-bottom: 10px;
      background-size: 100% auto;
      color: #ffe2d0;
    }
    .title {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #16172a;
      padding: 20px 10px;
      box-sizing: border-box;
      line-height: 1.5rem;
    }
    .time {
      color: #c1c1c1;
      font-size: 12px;
      padding-left: 17px;
    }
    .announcement-content {
      margin-top: 20px;
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      color: #16172a;
      line-height: 20px;
      text-align: justify;
      border-top: 1px solid #e4e1e1;
      padding-top: 10px;
    }
  }
}
</style>
